<template>
  <div id="content">
      <div class="con_sm">
        <!-- 内容模块 -->
         <contentModel v-if="index == 0"></contentModel>
         <contentTwo v-if="index == 1"></contentTwo>
         <contentThree v-if="index == 2"></contentThree>
         <contentFour v-if="index == 3"></contentFour>
         <contentFive v-if="index == 4"></contentFive>
      </div>
      <p @click="$router.go(-1)">返&nbsp;&nbsp;&nbsp;&nbsp;回</p>
  </div>
</template>
<script>
import contentModel from '../components/news/Content.vue'
import contentTwo from '../components/news/Content_two.vue'
import contentThree from '../components/news/Content_three.vue'
import contentFour from '../components/news/Content_four.vue'
import contentFive from '../components/news/Content_five.vue'
export default {
  components:{
    contentModel,
    contentTwo,
    contentThree,
    contentFour,
    contentFive
  },
  data(){
    return{
      index:'',
    }
  },
  created(){
    this.index = this.$route.query.index
  }
}
</script>
<style lang="scss" scoped>
#content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #051b8e;
  >p{
      width: 104px;
      height: 34px;
      border: 1px solid #9b9d78;
      border-radius: 12%;
      text-align: center;
      line-height: 34px;
      font-size: 20px;
      margin: 86px 0;
      cursor: pointer;
   }
  .con_sm{
    width: 1417px;
    border: 2px solid #9b9d78;
    margin: 129px 0 0 0;
   
  }
}
</style>